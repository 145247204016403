export const ENVIRONMENT = [
  ['production', window.location.origin.includes('capacitor://localhost')],
  ['development', !window.location.origin.includes('capacitor://localhost') && !window.location.origin.includes('legalai.co') && !window.location.origin.includes('legalai.pages.dev')],
  ['staging', window.location.origin.includes('beta.legalai.co')],
  ['production', !window.location.origin.includes('beta.legalai.co') && ( window.location.origin.includes('legalai.co') || window.location.origin.includes('legalai.pages.dev'))]
].find(([name, isActive]) => isActive)[0]

export const CLIENT_HOST = {
   development: `http://localhost:3000`,
   staging: `https://beta.legalai.co`,
   production: `https://legalai.co`
}[ENVIRONMENT]

export const API_HOST = {
  development: 'http://localhost:5005/api/v1',
  staging: 'https://api.sendboth.com/api/v1',
  production: 'https://api.sendboth.com/api/v1'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: null,
  production: null
}[ENVIRONMENT]
