import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getResult = async (query, personality, page, rowsPerPage, type, locale, filterExplicit, timeRange) => {
  if(!query){
    return false
  }
  const { token, userID } = ''//useCurrentUser();
  try {
    const { data } = await axios.get(`${API_HOST}/use_ai?type=personality&prompt=${query}&personality=ai_lawyer`,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-legalai-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
