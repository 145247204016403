import React, {useContext} from 'react';
import {Card, CardContent, makeStyles, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {SearchContext, ThemeContext} from "../../lib/contexts";
const useStyles = makeStyles({
   smallCard: {
      minWidth: 200,
      cursor:'pointer'
   },
   smallCardMobile: {
      minWidth: 200,
      textAlign:'center',
      cursor:'pointer',
      fontSize: '80%',
   },
   bullet: {
   display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
},
title: {
   fontSize: 14,
},
tagline: {
   marginBottom: 12,
},
taglineMobile: {
      fontSize: 14,
},
version : {
   fontSize:13,
      fontWeight:'bold',
      color:'rgba(0, 0, 0, 0.87)'
},
versionDark : {
   fontSize:13,
      fontWeight:'bold',
      color: '#fafafa',
},
   loadingBar: {
      width:'80%',
      margin:'40px auto 30px'
   },
});
const TitleBox = () => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const { darkMode } = useContext(ThemeContext);
   const {
      actions: { setQuery },
   } = useContext(SearchContext);
   return(
      <Card className={matchSM ? classes.smallCardMobile : classes.smallCard}>
         <CardContent onClick={()=>setQuery('')}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
               &nbsp;
            </Typography>
            <Typography variant="h5" component="h2">
               LegalAI <span className={darkMode ? classes.versionDark : classes.version}>v0.1</span>
            </Typography>
            <Typography className={matchSM ? classes.taglineMobile : classes.tagline}>
               Ask the AI Lawyer.
            </Typography>
         </CardContent>
      </Card>
)
};
export default TitleBox;
