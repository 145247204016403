import React from 'react';
import {Avatar, Card, CardActions, CardContent, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
      sponsoredBox: {
    marginTop:20,
      minWidth: 200,
         display: 'none'
   },
   title: {
      fontSize: 14,
   },
   small: {
      fontSize:14
   },
   sponsored: {
      maxHeight: 200,
      width:'100%',
      height:'100%',
      margin:'auto',
      display:'block'
   }
   }
));
const SponsoredBox = () => {
   const classes = useStyles();
   return(
<Card className={classes.sponsoredBox}>
   <CardContent>
     {/*<img alt="sponsored" src={process.env.PUBLIC_URL + '/sendboth.png'} className={classes.sponsored} />*/}
     <a href={'https://sendboth.com'} target={'_blank'}>
     <Avatar
        variant={'square'}
        alt="Marketing automation platform"
        src={process.env.PUBLIC_URL + '/sendboth.png'} className={classes.sponsored} />
     </a>
   </CardContent>

   <CardActions disableSpacing>
      <Typography  className={classes.small}>
         sponsored
      </Typography>
   </CardActions>
</Card>
)
};

export default SponsoredBox;
