import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {SearchContext} from "../../lib/contexts";
const IndexTicker = () => {
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: { indexCount, entityCount, personalityList },
   } = useContext(SearchContext);
   /*
   const [currentEntityCount, setCurrentEntityCount] = useState(entityCount);
   const [currentCount, setCurrentCount] = useState(indexCount);
   useEffect(() => {
      setCurrentCount(indexCount);
      setCurrentEntityCount(entityCount);
   },[indexCount,entityCount]);
   const increment = useCallback(()=>{
      setCurrentCount(currentCount + 1);
      setCurrentEntityCount(currentEntityCount + 1);
   },[currentCount, currentEntityCount]);
   useEffect(() => {
      if(indexCount){
         const interval = setInterval(increment, 2000);
         return () => clearInterval(interval);
      }
   },[currentCount, indexCount, increment]);
    */
   return(<Typography gutterBottom style={{fontSize:matchSM ? 14.49 : 'inherit'}}>
      "I am an AI lawyer and cannot yet be licensed to provide legal advice. Use at your own risk."
      </Typography>)
};
export default IndexTicker;
